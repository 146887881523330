import React, { FC, useEffect, useMemo, useState } from 'react'
import { isSessionStorageAvailable } from '@moonpig/web-core-utils'
import { useStore } from '@moonpig/web-core-stores'
import {
  createGenericSelectContentEvent,
  useTrackGAEventOnce,
  trackGAEvent,
} from '@moonpig/web-core-analytics'
import { dispatcherContext, eventContext } from './Context'
import { Dispatcher, ProductModalClosed, ProductModalShown } from './types'
import { ProductModal } from '../../components/ProductModal'
import type {
  AddedToBasketEvent,
  ProductContext,
  ProductInfoProduct,
  UpsellProduct,
} from '../../types'
import { OnConfigureModulesCallback } from '../../modules/types'
import { useDefaultModuleConfiguration } from '../../modules/configurations'
import { useRecentlyViewedDispatcher } from '../recentlyViewed'
import {
  createProductDetailsScreenView,
  createViewItemsWithAddonsGAEvent,
} from '../../analytics'

type State = {
  index?: number
  product?: ProductInfoProduct
  upsellProduct?: UpsellProduct
  groupCardProject?: string
  triggerElementRef?: React.RefObject<HTMLAnchorElement>
  isOpen: boolean
  configuration: {
    showProductTabs: boolean
    showStickyCta: boolean
  }
  onAddToBasket: (event: AddedToBasketEvent) => void
}

const EMPTY_STATE = {
  isOpen: false,
  configuration: {
    showProductTabs: false,
    showStickyCta: false,
  },
  onAddToBasket: /* istanbul ignore next*/ () => {},
}

export const ProductModalProvider: FC<
  React.PropsWithChildren<{
    context: ProductContext
    onConfigureModules?: OnConfigureModulesCallback
  }>
> = ({ context, children, onConfigureModules }) => {
  const { addToRecentlyViewed } = useRecentlyViewedDispatcher()
  const configureModules = useDefaultModuleConfiguration(context)
  const { store } = useStore()
  const { trackGAEventOnce } = useTrackGAEventOnce()

  const [state, setState] = useState<State>(EMPTY_STATE)
  const [event, setEvent] = useState<
    ProductModalClosed | ProductModalShown | null
  >(null)

  const handleModalVisibility = (eventType: 'open' | 'close', id = '') => {
    const url = new URL(window.location.href)

    if (eventType === 'open') {
      if (isSessionStorageAvailable()) {
        window.sessionStorage.setItem('mnpg_product_id', id)
      }
      url.searchParams.set('productId', id)
      window.history.pushState({ productId: id }, '', url.toString())
    }

    if (eventType === 'close') {
      if (isSessionStorageAvailable()) {
        sessionStorage.removeItem('mnpg_product_id')
      }
      window.history.back()
    }
  }

  const actions: Dispatcher = useMemo(() => {
    return {
      show: params => {
        setState({ ...params, isOpen: true })
        setEvent({ type: 'PRODUCT_MODAL_SHOWN' })
        addToRecentlyViewed(params)
        handleModalVisibility('open', params.product.id)
      },
      close: () => {
        trackGAEvent(
          createGenericSelectContentEvent({
            eventCategory: `${context.pageType} action`,
            eventAction: 'close',
            eventLabel: 'product details',
            itemId: state.product?.id,
          }),
        )
        setEvent({ type: 'PRODUCT_MODAL_CLOSED' })
        handleModalVisibility('close')
      },
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToRecentlyViewed])

  const listener = () => {
    const url = new URL(window.location.href)
    const productId = url.searchParams.get('productId')
    const sessionProductId = isSessionStorageAvailable()
      ? window.sessionStorage.getItem('mnpg_product_id')
      : /* istanbul ignore next */ ''

    /* istanbul ignore else */
    if (!productId || sessionProductId !== productId) {
      setState({ ...state, isOpen: false })
      setEvent({ type: 'PRODUCT_MODAL_CLOSED' })
    }
  }

  useEffect(() => {
    window.addEventListener('popstate', listener)

    return () => {
      window.removeEventListener('popstate', listener)
    }
  })

  useEffect(() => {
    if (state.isOpen && state.product) {
      const product = state.product
      const {
        id,
        category,
        title,
        masterVariant,
        isSponsored,
        internallyPromoted,
      } = product

      trackGAEventOnce(createProductDetailsScreenView({ store, product }))
      trackGAEventOnce(
        createViewItemsWithAddonsGAEvent({
          product: {
            id,
            category: category.name,
            title,
            price: masterVariant.price.centAmount,
            index: 0,
            categoryDepartment: category.department,
            inStock: masterVariant.inStock,
            isSponsored,
            internallyPromoted,
          },
          variants: state.product.variants,
          pageLocation: 'product details',
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isOpen])

  useEffect(() => {
    if (event?.type === 'PRODUCT_MODAL_CLOSED') {
      if (state.triggerElementRef?.current) {
        const productElementToFocus = state.triggerElementRef.current
        setTimeout(() => {
          productElementToFocus.focus()
        }, 0)
      }

      setState(EMPTY_STATE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event])

  return (
    <dispatcherContext.Provider value={actions}>
      <eventContext.Provider value={event}>
        {children}
        {state.product && (
          <ProductModal
            {...state}
            product={state.product}
            upsellProduct={state.upsellProduct}
            productContext={context}
            onConfigureModules={onConfigureModules ?? configureModules}
            onDismiss={() => {
              trackGAEvent(
                createGenericSelectContentEvent({
                  eventCategory: `${context.pageType} action`,
                  eventAction: 'close',
                  eventLabel: 'product details',
                  itemId: state.product?.id,
                }),
              )
              setEvent({ type: 'PRODUCT_MODAL_CLOSED' })
              handleModalVisibility('close')
            }}
          />
        )}
      </eventContext.Provider>
    </dispatcherContext.Provider>
  )
}
