import { Box, Pill } from '@moonpig/launchpad-components'
import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { spacingPx } from '@moonpig/launchpad-theme'
import { system as s } from '@moonpig/launchpad-system'
import { PillVariant } from '../../../../types'

const StyledWrapper = styled(Box)`
  position: absolute;
  top: ${spacingPx(4)};
  z-index: 3;
`

const StyledPill = styled(Pill)<{
  colours: { color: string; bgColor: string }
}>`
  border-radius: ${spacingPx(6)};
  ${({ colours }) =>
    s({
      color: `${colours.color}`,
      bgcolor: `${colours.bgColor}`,
      maxHeight: spacingPx(8),
    })};
`

const variantToColour = {
  default: {
    bgColor: 'colorInteractionButton',
    color: 'colorTextInverted',
  },
  marketing: {
    bgColor: 'colorInteractionSelectedState',
    color: 'colorTextInverted',
  },
  price: {
    bgColor: 'colorFeedbackRating',
    color: 'colorTextHeadline',
  },
  eco: {
    bgColor: 'colorFeedbackSuccess',
    color: 'colorTextInverted',
  },
  plus: {
    bgColor: 'colorBackground07',
    color: 'colorTextInverted',
  },
  sponsored: {
    bgColor: 'colorBlack20',
    color: 'colorTextHeadline',
  },
}

export const TilePill: FC<{
  pill: {
    label: string
    variant: PillVariant
  }
}> = ({ pill }) => {
  return (
    <StyledWrapper>
      <StyledPill {...pill} colours={variantToColour[pill.variant]} />
    </StyledWrapper>
  )
}
