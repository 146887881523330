import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql/graphqlTypes'

const DIGITAL_GIFT_CATEGORIES = [
  DepartmentsEnum.DIGITAL_GIFT_CARDS,
  DepartmentsEnum.DIGITAL_GIFTS,
]

export const isDigitalGift = (department: DepartmentsEnum) =>
  DIGITAL_GIFT_CATEGORIES.includes(department)

export const isDigitalGiftCard = (department: DepartmentsEnum) =>
  department === DepartmentsEnum.DIGITAL_GIFT_CARDS
