import { useContext } from 'react'
import { useSessionId, EppoContext } from '@moonpig/web-core-utils'
import { VariationType } from '@eppo/js-client-sdk-common'
import { logger } from '@moonpig/web-core-monitoring'

export const useAllEppoExperiments = () => {
  const eppoClient = useContext(EppoContext)
  const { sessionId } = useSessionId()

  if (!eppoClient || !sessionId) {
    logger.fixToday(
      'Error calling useAllEppoExperiments: eppoClient or sessionId is null',
    )
    return {}
  }

  const allFlags = eppoClient.getFlagKeys()
  const flagConfiguration = eppoClient.getFlagConfigurations()

  const experiments: Record<string, string> = {}

  allFlags.forEach(flagKey => {
    const { variationType } = flagConfiguration[flagKey]

    switch (variationType) {
      case VariationType.STRING:
        experiments[flagKey] = eppoClient.getStringAssignment(
          flagKey,
          sessionId,
          { shouldLog: false },
          'control',
        )
        break

      case VariationType.BOOLEAN:
        experiments[flagKey] = eppoClient
          .getBooleanAssignment(flagKey, sessionId, { shouldLog: false }, false)
          .toString()
        break
      case VariationType.INTEGER:
        experiments[flagKey] = eppoClient
          .getIntegerAssignment(flagKey, sessionId, { shouldLog: false }, 0)
          .toString()
        break
    }
  })

  return experiments
}
