import { gql } from '@moonpig/web-core-graphql'

export const GetBasketGQL = gql`
  query GetBasket {
    me {
      basket {
        id
        lineItems {
          sku
          id
          productType
          size
          quantity
          title
          digitalGift {
            __typename
            title
            sku
            image {
              url
            }
          }
          design {
            id
            images {
              ... on SceneImages {
                images {
                  title
                  variants {
                    small {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
