import { SelectContentEvent } from '@moonpig/web-core-analytics'

export const createModalSizeChangeGAEventDetails = (
  sizeId: string,
): SelectContentEvent => {
  return {
    event: 'select_content',
    event_data: {
      category: 'product action',
      action: 'select size',
      label: sizeId,
      non_interaction: true,
    },
  }
}
