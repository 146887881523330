import { SelectContentEvent, trackGAEvent } from '@moonpig/web-core-analytics'
import {
  AccordionSectionAction,
  AccordionSectionNameLookup,
  AccordionSection,
} from '../../types'

export const trackAccordionSectionClick = ({
  currentSections,
  updatedSections,
  productId,
}: {
  currentSections: AccordionSection[]
  updatedSections: AccordionSection[]
  productId: string
}) => {
  if (updatedSections.length > currentSections.length) {
    const openSection = updatedSections.filter(
      section => !currentSections.includes(section),
    )[0]

    trackGAEvent(
      sectionClickEvent({
        productId,
        section: openSection,
        action: AccordionSectionAction.OPEN,
      }),
    )
  } else {
    const closedSection = currentSections.filter(
      section => !updatedSections.includes(section),
    )[0]

    trackGAEvent(
      sectionClickEvent({
        productId,
        section: closedSection,
        action: AccordionSectionAction.CLOSE,
      }),
    )
  }
}

const sectionClickEvent = ({
  productId,
  section,
  action,
}: {
  productId: string
  section: AccordionSection
  action: AccordionSectionAction
}): SelectContentEvent => {
  const sectionName = AccordionSectionNameLookup[section]

  return {
    event: 'select_content',
    content_data: {
      content_type: `product details | ${productId} | accordion | ${sectionName} | ${action}`,
    },
    event_data: {
      category: 'product details',
      action: 'view item',
      label: `product details | ${productId} | accordion | ${sectionName} | ${action}`,
      non_interaction: true,
      value: undefined,
    },
    error_data: undefined,
    ecommerce: undefined,
  }
}
