import {
  createBasketEvent,
  sanitizeStrings,
  ProductSelectedEventProperties,
  Product,
} from '@moonpig/web-core-analytics'
import { BRAND_LABEL } from '@moonpig/web-core-brand/config'
import { Region } from '@moonpig/web-core-types'
import { getParentDepartment } from '@moonpig/web-core-utils'
import { getDeviceType } from '@moonpig/web-shared-utils'
import {
  ProductInfoProduct,
  ProductList,
  Variant,
  GiftXSellTrackingData,
} from '../types'
import { AddonsFieldsFragment } from '../queries/useProductWithAddonsQuery/__generated__/useProductWithAddonsQuery'
import { isCardProduct } from '../utils/isCardProduct'
import { isSponsoredCheck } from './utils'

export type ProductCartWithAddonEventDetails = {
  basketId: string
  quantity: number
  totalItems?: number
  product: ProductInfoProduct
  variant: Variant
  selectedAddon: AddonsFieldsFragment | null | undefined
  itemIndex: number
  label: string
  listName: string
}

export const createAddToBasketGAEvent = ({
  basket,
  currency,
  products,
  label,
  totalValue,
  productIndex,
  eventCategory,
}: {
  basket: {
    id: string
    size: number
    action: string
  }
  products: Product[]
  currency: string
  totalValue: number
  label: string
  productIndex?: number
  listName?: string
  eventCategory?: string
}) => {
  return createBasketEvent({
    event: 'add_to_cart',
    action: 'add to basket',
    basket,
    currency,
    products,
    label,
    totalValue,
    productIndex,
    eventCategory,
  })
}

const getGAEventsProducts = ({
  product,
  variant,
  quantity,
  selectedAddon,
  itemLabel,
}: {
  product: ProductInfoProduct
  variant: Variant
  quantity: number
  selectedAddon?: AddonsFieldsFragment | null
  itemLabel: string
  productCentAmount: number
}): Product[] => {
  const products = []
  const item = {
    item_brand: BRAND_LABEL.toLowerCase(),
    item_id: String(product.id),
    item_name: product.title.toLocaleLowerCase(),
    item_variant: variant.title.toLocaleLowerCase(),
    item_category: product?.category?.name,
    item_category2:
      product?.category &&
      product?.category?.department &&
      getParentDepartment(product.category.department),
    item_category3: /* istanbul ignore next */ variant.inStock
      ? 'in-stock'
      : 'out-of-stock',
    item_category4: 'non-group-card',
    item_category5: /* istanbul ignore next */ product?.isFavourite
      ? 'favourite'
      : 'non-favourite',
    item_list_name: itemLabel,
    price: variant.price.centAmount / 100,
    quantity,
    is_sponsored: isSponsoredCheck(
      product.isSponsored,
      product.internallyPromoted,
    ),
  }
  products.push(item)

  /* istanbul ignore next */
  if (selectedAddon) {
    const addonItem = {
      item_id: selectedAddon.sku.toLowerCase(),
      item_name: selectedAddon.title.toLowerCase(),
      item_brand: BRAND_LABEL.toLowerCase(),
      item_variant: 'add on',
      item_category3: 'in-stock',
      item_category4: 'non-group-card',
      item_category5: 'non-favourite',
      item_list_name: itemLabel,
      price: selectedAddon.price.centAmount / 100,
      quantity: isCardProduct(product.category.department) ? 1 : quantity,
    }
    products.push(addonItem)
  }

  return products
}

export const getGAEventLabels = ({
  product,
  productList,
  selectedAddon,
  itemIndex,
  productsLength,
  pageLocation,
  giftXSellTrackingData,
}: {
  product: ProductInfoProduct
  productList: ProductList | undefined
  selectedAddon: AddonsFieldsFragment | null | undefined
  itemIndex: number
  productsLength: number
  pageLocation: string
  giftXSellTrackingData?: GiftXSellTrackingData
}): {
  label: string
  listName: string
  itemIndex: number
} => {
  const productIndexInTotal = `${itemIndex + 1}/${productsLength}`
  let carouselLabel = productIndexInTotal
  const addonLabel = selectedAddon?.sku ? ` + addon: ${selectedAddon.sku}` : ''

  if (productList) {
    const tabbedCarousel = productList?.tabbedModuleTitle
    const carouselType = tabbedCarousel ? 'tabbed carousel' : 'carousel'
    const tabbedCarouselTab = tabbedCarousel
      ? `${productList.tabbedModuleTitle?.trim()} tab | `
      : ''
    const productListPosition = `${productList.position}/${productList.totalNumberOfLists}`

    carouselLabel = `${productList.title} | ${carouselType} | ${productListPosition} | ${tabbedCarouselTab}${productIndexInTotal}`
  }

  if (giftXSellTrackingData) {
    const giftXSellproductIndexInTotal = `${
      giftXSellTrackingData.currentIndex + 1
    }/${giftXSellTrackingData.totalProducts}`
    const listName = `${pageLocation} | ${pageLocation} xsell | gifts | 1/1 | ${giftXSellproductIndexInTotal}`
    const label = `${pageLocation} | ${pageLocation} xsell | gifts | 1/1 | ${giftXSellproductIndexInTotal}`
    return {
      listName,
      label,
      itemIndex,
    }
  }

  const listName = `${pageLocation} | ${carouselLabel}`
  const label = sanitizeStrings(
    `${listName} | ${product.id}${addonLabel}`,
  ).substring(0, 100)

  return {
    listName,
    label,
    itemIndex,
  }
}

const getGAEventAmounts = ({
  product,
  variant,
  selectedAddon,
  quantity,
}: {
  product: ProductInfoProduct
  variant: Variant
  selectedAddon: AddonsFieldsFragment | null | undefined
  quantity: number
}): {
  totalValue: number
  productCentAmount: number
} => {
  const isCard = isCardProduct(product.category.department)
  const productCentAmount = variant.price.centAmount
  const productTotalCentAmount = productCentAmount * quantity

  const addonCentAmount = selectedAddon?.price?.centAmount ?? 0
  const addonQuantity = isCard ? 1 : quantity
  const addonTotalCentAmount = addonCentAmount * addonQuantity

  const formattedPrice = Number(
    ((productTotalCentAmount + addonTotalCentAmount) / 100).toFixed(2),
  )
  return {
    totalValue: Number(formattedPrice),
    productCentAmount,
  }
}

export const createAddToBasketWithAddonGAEvent = ({
  basketId,
  quantity,
  totalItems,
  product,
  variant,
  selectedAddon,
  itemIndex,
  label,
  listName,
}: ProductCartWithAddonEventDetails) => {
  const { totalValue, productCentAmount } = getGAEventAmounts({
    product,
    variant,
    selectedAddon,
    quantity,
  })

  return createBasketEvent({
    event: 'add_to_cart',
    action: 'add to basket',
    basket: {
      id: basketId,
      size: /* istanbul ignore next */ totalItems || quantity,
      action: 'add to cart',
    },
    currency: product.masterVariant.price.currencyCode,
    products: getGAEventsProducts({
      product,
      variant,
      quantity,
      selectedAddon,
      itemLabel: listName,
      productCentAmount,
    }),
    label,
    totalValue,
    productIndex: /* istanbul ignore next */ itemIndex
      ? itemIndex + 1
      : undefined,
  })
}

export const getProductSelectedEventData = ({
  correlationId,
  product,
  region,
  searchTerm,
  filters,
  experiments,
  departments,
  customerId,
}: {
  correlationId: string
  region: Region
  product: ProductInfoProduct
  filters?: { facetKey: string; group: string }[]
  experiments?: { [key: string]: string } | undefined
  departments?: string[]
  searchTerm?: string
  customerId?: string
}): ProductSelectedEventProperties => ({
  searchTerm: searchTerm || '',
  correlationId,
  productId: product.id,
  store: region.toUpperCase(),
  /* istanbul ignore next */
  ...(filters &&
    filters?.length > 0 && {
      filters: filters?.map(x => ({
        facetKey: x.facetKey,
        group: undefined,
      })),
    }),
  /* istanbul ignore next */
  ...(experiments && { metaData: { experiments } }),
  department: departments,
  source: getDeviceType(),
  customerId,
})

export const getQueryStringForEditorLocation = ({
  sku,
  quantity,
  addonSku,
}: {
  sku: string | null
  quantity: number
  addonSku?: string
}): string => {
  const queryParams: [string, string | number | boolean][] = [
    ['productVariantSku', String(sku)],
  ]
  /* istanbul ignore next */
  if (quantity > 1) {
    queryParams.push(['quantity', quantity])
  }

  if (addonSku) {
    queryParams.push(['addonSku', addonSku])
  }

  return queryParams
    .map(parts => parts.map(encodeURIComponent).join('='))
    .join('&')
}
