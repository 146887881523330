import { useQuery } from '@moonpig/web-core-graphql'
import { logger } from '@moonpig/web-core-monitoring'
import { useExperimentString } from '@moonpig/web-core-eppo/browser'
import { GetPeopleAlsoViewedGQL } from './query'
import { ProductInfoProduct } from '../../types'
import {
  GetPeopleAlsoViewedQuery,
  GetPeopleAlsoViewedQueryVariables,
} from './__generated__/query'

const DEFAULT_LIMIT = 5

const getData = (
  data?: GetPeopleAlsoViewedQuery,
  previousData?: GetPeopleAlsoViewedQuery,
) => {
  return data || previousData || null
}

export const usePeopleAlsoViewedQuery = ({
  product,
  limit = DEFAULT_LIMIT,
}: {
  product: ProductInfoProduct
  limit?: number
}) => {
  const peopleAlsoViewedExperiment = useExperimentString(
    'people-also-viewed-alternative-algorithm',
    {
      fallback: 'control',
    },
  )

  const algorithmId =
    peopleAlsoViewedExperiment === 'control'
      ? 'CustomersAlsoViewed'
      : 'CustomersAlsoViewed_variant'

  const { data, previousData, error } = useQuery<
    GetPeopleAlsoViewedQuery,
    GetPeopleAlsoViewedQueryVariables
  >(GetPeopleAlsoViewedGQL, {
    variables: { productId: product.id, algorithmId },
  })

  if (error) {
    logger.fixToday('Failed to load product recommendations', {
      productId: product.id,
    })
    return []
  }

  const result = getData(data, previousData)

  const products =
    result?.productLookup.products[0]?.peopleAlsoViewed?.products.filter(
      Boolean,
    ) as ProductInfoProduct[]

  return products?.length ? [product, ...products].slice(0, limit) : []
}
