import React from 'react'
import { SponsoredPill } from '../../SponsoredPill'
import { TilePill as Pill } from './components/Pill'
import { PillVariant } from '../../../types'

type Props = {
  isSponsored: boolean
  pill:
    | {
        label: string
        variant: PillVariant
      }
    | undefined
}

export const createPill = ({ isSponsored, pill }: Props) => {
  if (isSponsored) {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        onClick={
          /* istanbul ignore next */ (
            e: React.MouseEvent<HTMLElement, MouseEvent>,
          ) => e.stopPropagation()
        }
      >
        <SponsoredPill />
      </div>
    )
  }
  if (pill) {
    return <Pill pill={pill} />
  }

  return null
}
