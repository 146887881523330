import { useApolloClient } from '@moonpig/web-core-graphql'
import { GetBasketGQL } from '../../queries/addDigitalGiftToBasket/getCardOptions'
import { GetBasketQuery } from '../../queries/addDigitalGiftToBasket/__generated__/getCardOptions'
import {
  GetBasket_me_basket_lineItems as LineItem,
  GetBasket_me_basket_lineItems_design as Design,
  GetBasket_me_basket_lineItems_design_images_SceneImages as Image,
} from './types'
import { ProductInfoProduct } from '../../types'

export type CardLineItem = Omit<LineItem, 'design'> & {
  design: Design & { coverImageUrl: string }
}

export const useExistingCardOptions = () => {
  const client = useApolloClient()

  const getExistingCardOptions = async ({
    selectedDigitalGift,
    digitalGiftsWithECardsFeatureEnabled,
  }: {
    selectedDigitalGift: ProductInfoProduct
    digitalGiftsWithECardsFeatureEnabled: boolean
  }) => {
    const includeEcards = (option: LineItem) =>
      digitalGiftsWithECardsFeatureEnabled || option.size !== 'ECard'
    const { data, loading } = await client.query<GetBasketQuery>({
      query: GetBasketGQL,
    })

    const cardsInBasketEligibleForDigitalGift =
      (data?.me.basket?.lineItems.filter(
        option =>
          option.productType === 'greetingcard' &&
          option.design !== null &&
          option.quantity === 1 &&
          includeEcards(option) &&
          option.digitalGift?.sku !== selectedDigitalGift.masterVariant.sku &&
          getCoverImageUrl(option.design),
      ) ?? []) as CardLineItem[]

    if (!data?.me.basket?.lineItems.length) {
      return { basket: null, loading }
    }

    return {
      basket: {
        id: data.me.basket.id,
        existingCardOptions: cardsInBasketEligibleForDigitalGift.map(
          option => ({
            ...option,
            design: {
              ...option.design,
              coverImageUrl: getCoverImageUrl(option.design) as string,
            },
          }),
        ),
      },
      loading,
    }
  }
  return { getExistingCardOptions }
}

const getCoverImageUrl = (design: Design) => {
  if (design.images.__typename !== 'SceneImages') {
    return null
  }
  return (design.images as Image).images[0].variants.small.url
}
