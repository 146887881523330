import { useRouter } from '@moonpig/web-core-routing'
import { useBasketTotalItems, useCustomerId } from '@moonpig/web-shared-utils'
import { useMutation } from '@moonpig/web-core-graphql'
import { useStoreId } from '@moonpig/web-core-stores'
import { getBrowserCookie } from '@moonpig/web-core-cookies'
import { useExperimentString } from '@moonpig/web-core-eppo/browser'
import { addToBasket } from './addToBasket'
import { MasterVariant as AddDigitalGiftsVariant } from '../addDigitalGiftToBasket/types'
import { useAddDigitalGiftWithCardToBasket } from '../addDigitalGiftToBasket/useAddDigitalGiftWithCardToBasket'
import {
  ButtonActionName,
  ProductInfoProduct,
  ProductList,
  Variant,
  GiftXSellTrackingData,
} from '../../types'
import { useAddCardToBasketAndAssignDesign } from '../addDigitalGiftToBasket/useAddCardToBasketAndAssignDesign'
import { useCreateEmptyDesign } from '../addDigitalGiftToBasket/useCreateEmptyDesign'
import {
  AddToBasketAndSplitFind,
  AddToBasketAndSplitGQL,
  AddToBasketFind,
  AddToBasketFindVariables,
  AddToBasketGQL,
} from '../../queries/addToBasket'
import { AddToBasketAndSplitFindMutationVariables } from '../../queries/addToBasket/__generated__/addToBasketAndSplit'
import { AddonsFieldsFragment } from '../../queries/useProductWithAddonsQuery'
import { isCardProduct } from '../isCardProduct'
import {
  isDigitalGift,
  isDigitalGiftCard,
} from '../isDigitalGift/isDigitalGift'
import { useAddDigitalGiftToExistingCard } from '../addDigitalGiftToBasket/useAddDigitalGiftToExistingCard'
import { useExistingCardOptions } from '../addDigitalGiftToBasket/useExistingCardOptions'

export type HandleAddToBasketProps = {
  quantity: number
  product: ProductInfoProduct
  variant: Variant
  filters?: { facetKey: string; group: string }[]
  experiments?: { [key: string]: string } | undefined
  searchTerm?: string
  selectedAddon?: AddonsFieldsFragment | null
  itemIndex: number
  productsLength: number
  buttonActionName: ButtonActionName | undefined
  requiresEditingValue?: boolean
  componentName?: string
  trackPersonaliseEvent?: boolean
  redirectUser?: boolean
  giftXSellTrackingData?: GiftXSellTrackingData
  onTackingInfo?: (args: { product: ProductInfoProduct }) => {
    label: string
    listName: string
    itemIndex: number
  }
}

export type UseHandleAddToBasketProps = {
  pageLocation: string
  soldOutMessage: string
  itsNotYouMessage: string
  productList?: ProductList
  groupCardProject?: string
}

export const useHandleAddToBasket = ({
  pageLocation,
  soldOutMessage,
  itsNotYouMessage,
  productList,
  groupCardProject,
}: UseHandleAddToBasketProps) => {
  const storeId = useStoreId()
  const router = useRouter()
  const { getTotalItems } = useBasketTotalItems()
  const customerId = useCustomerId()
  const { addDigitalGiftWithCardToBasket, loading: addingDigitalGiftToBasket } =
    useAddDigitalGiftWithCardToBasket()
  const {
    addCardToBasketAndAssignDesign,
    addingToBasket: addingEmptyDesignToBasket,
  } = useAddCardToBasketAndAssignDesign()
  const { createEmptyDesign } = useCreateEmptyDesign()

  const [addToBasketMutation, { loading: addingToBasket }] = useMutation<
    AddToBasketFind,
    AddToBasketFindVariables
  >(AddToBasketGQL, {
    onCompleted: () => {
      getTotalItems()
    },
  })

  const [addToBasketAndSplitMutation, { loading: addingToBasketAndSplitting }] =
    useMutation<
      AddToBasketAndSplitFind,
      AddToBasketAndSplitFindMutationVariables
    >(AddToBasketAndSplitGQL, {
      onCompleted: () => {
        getTotalItems()
      },
    })

  const { getExistingCardOptions } = useExistingCardOptions()

  const getSelectedCard = async (digitalGift: ProductInfoProduct) => {
    const selectedLineItemId = getBrowserCookie('gift-card-line-item-id')

    const { basket } = await getExistingCardOptions({
      selectedDigitalGift: digitalGift,
      digitalGiftsWithECardsFeatureEnabled: true,
    })

    if (!basket) {
      return { selectedCard: undefined, basketId: undefined }
    }

    const basketId = basket.id
    const existingCardOptions = basket.existingCardOptions
    const selectedCard =
      existingCardOptions.length > 1
        ? existingCardOptions.find(({ id }) => id === selectedLineItemId)
        : existingCardOptions[0]

    return { selectedCard, basketId }
  }

  const { addDigitalGiftToExistingCard } = useAddDigitalGiftToExistingCard()
  const giftCardsExperimentEnabled =
    useExperimentString('explore-basket-gift-cards', {
      fallback: 'control',
    }) === 'variant'

  const handleAddToBasket = async ({
    quantity,
    product,
    variant,
    filters,
    experiments,
    searchTerm,
    selectedAddon,
    itemIndex,
    productsLength,
    buttonActionName,
    requiresEditingValue,
    componentName,
    trackPersonaliseEvent,
    redirectUser = true,
    giftXSellTrackingData,
    onTackingInfo,
  }: HandleAddToBasketProps) => {
    if (
      isDigitalGiftCard(product.category.department) &&
      giftCardsExperimentEnabled
    ) {
      const { selectedCard, basketId } = await getSelectedCard(product)

      if (basketId && selectedCard) {
        await addDigitalGiftToExistingCard({
          existingCard: selectedCard,
          basketId,
          digitalGift: product,
        })

        return
      }
    }
    if (isDigitalGift(product.category.department)) {
      await addDigitalGiftWithCardToBasket({
        productId: product.id,
        digitalGiftProductMasterVariant:
          product.masterVariant as AddDigitalGiftsVariant,
        region: storeId,
        customerId,
      })

      return
    }
    const shouldRedirectUserOnItemAdded = !!redirectUser

    const shouldUseSplitMutation =
      !isCardProduct(product.category.department) &&
      !product.customisable &&
      quantity > 1

    const {
      error,
      windowLocation,
      nextPage = 'basket',
    } = await addToBasket({
      region: storeId,
      quantity,
      product,
      variant,
      filters,
      experiments,
      searchTerm,
      addonSku: selectedAddon?.sku,
      customerId,
      soldOutMessage,
      itsNotYouMessage,
      buttonActionName,
      groupCardProject,
      productList,
      componentName,
      trackPersonaliseEvent,
      selectedAddon,
      itemIndex,
      productsLength,
      pageLocation,
      addCardToBasketAndAssignDesign,
      createEmptyDesign,
      addToBasketMutation: shouldUseSplitMutation
        ? addToBasketAndSplitMutation
        : addToBasketMutation,
      requiresEditingValue,
      giftXSellTrackingData,
      onTackingInfo,
    })

    if (error) {
      throw new Error(error)
    } else if (shouldRedirectUserOnItemAdded) {
      if (windowLocation) {
        window.location.assign(windowLocation)
      } else if (nextPage === 'add-a-card') {
        router.push({
          name: nextPage,
          params: { region: storeId, quantity, version: 1 },
        })
      } else {
        router.push({ name: nextPage, params: { region: storeId, quantity } })
      }
    }
    return {
      addingToBasket: addingToBasket || addingToBasketAndSplitting,
    }
  }

  return {
    handleAddToBasket,
    addingToBasket:
      addingToBasket ||
      addingToBasketAndSplitting ||
      addingEmptyDesignToBasket ||
      addingDigitalGiftToBasket,
  }
}
