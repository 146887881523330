import React, { FC } from 'react'
import { Box, Flex, Text } from '@moonpig/launchpad-components'
import { formatPrice } from '@moonpig/web-core-utils'
import { useProductsLocaleText } from '../../locale'
import { useProductStore } from '../../contexts/product'
import { E_CARD_SIZE_ID } from '../../constants'

export const VariantListModule: FC = () => {
  const localiseText = useProductsLocaleText()
  const [
    {
      product: { variants },
    },
  ] = useProductStore()

  const variantsWithoutECard = variants.filter(
    variant => variant.key !== E_CARD_SIZE_ID,
  )

  return (
    <Box data-testid="module-variant-list" mb={4}>
      <Box mb={4}>
        <Text
          typography={{ xs: 'typeMobileDisplay03', md: 'typeDesktopDisplay05' }}
          color={'colorTextHeadline'}
        >
          {localiseText('variant_tabs.sizes_available')}
        </Text>
      </Box>
      {variantsWithoutECard.map(variant => (
        <Flex
          key={variant.key}
          flexDirection="row"
          justifyContent="space-between"
          py={4}
          color={'colorTextLabel'}
        >
          <Text>{variant.title.split(' ')[0]}</Text>
          <Text>
            {formatPrice(
              variant.price.centAmount,
              variant.price.fractionDigits,
              variant.price.currencyCode,
            )}
          </Text>
        </Flex>
      ))}
    </Box>
  )
}
